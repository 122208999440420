<template>
    <menu>
        <router-link to="/" class="fal fa-home-lg-alt"><br> <span>Home</span></router-link>
		<router-link to="/order" class="fal fa-history"><br> <span>Order</span></router-link>
		<router-link to="/mitra/dashboard" class="fal fa-store" v-if="role == 'mitra'"><br> <span>Mitra</span></router-link>
        <router-link to="/profile" class="fal fa-user"><br> <span>Profile</span></router-link>
    </menu>
</template>

<script>
export default {
	data() {
		return {
			role: localStorage.getItem('role')
		}
	},	
}
</script>

<style scoped>

menu {
	position: fixed;
	padding: 0;
	bottom: 0;
	margin: 0;
	z-index: 100;
	background-color: white;
	height: 60px;
	width: 375px;
    border-top: 1px solid rgba(0, 0, 0, .1);
	display: flex;
	justify-content: space-around;
	align-items: center;
    transition: .3s;
}

menu a {
    font-size: 1.2rem;
	cursor: pointer;
	color: gray;
    transition: .3s;
    text-decoration: none;
    text-align: center;
}

menu a span {
    font-family: 'poppins';
    font-size: 12px;
    text-align: center;
}

menu a:hover {
	color: var(--primary) !important;
}

a.router-link-exact-active {
	color: var(--primary);
}

</style>