<template>
    <div class="app">
		<div v-if="!token" class="auth pt-4 pb-4">
			<router-link to="/register" class="button secondary">Register</router-link>
			<router-link to="/login" class="button">Login</router-link>
		</div>

		<template v-if="token">
			<header>
				<a @click.prevent="$router.go(-1)" href="" class="back"><i class="fad fa-angle-left"></i></a>

				<p class="title">Your Orders</p>
			</header>

			<div class="container pt-4">
				<p v-if="datas.length == 0" class="text-center">There is no orders</p>

				<div class="orders">
					<form class="order mb-3" v-for="data in datas" :key="data.id" :id="`form${data.id}`">
						<h5 class="title">ORD-{{ data.id }}</h5>
						<p class="subtotal">Rp. {{ Number(data.subtotal).toLocaleString() }}</p>

						<p class="badge status bg-light-danger" v-if="data.status == 'Menunggu Pembayaran' || data.status == 'Dibatalkan'">{{ data.status }}</p>
						<p class="badge status bg-light-warning" v-if="data.status == 'Menuggu Verifikasi'">{{ data.status }}</p>
						<p class="badge status bg-light-success" v-if="data.status != 'Menunggu Pembayaran' && data.status != 'Menuggu Verifikasi' && data.status != 'Dibatalkan'">{{ data.status }}</p>

						<div class="d-flex justify-content-between align-items-center" v-if="data.image_url">
							<a :href="`order/${data.id}`" v-if="data.image_url" class="proof">Lihat Faktur</a>

							<button type="button" class="btn btn-sm btn-success" @click="done(data.id)" v-if="data.status != 'Diterima'">Pesanan Diterima</button>
						</div>
						<button type="button" @click="process = true, payment = data.payment_method, subtotal = data.subtotal, data_id = data.id" :for="data.id" class="btn btn-info btn-sm" v-if="!data.image_url">Upload Payment Proof</button>
					</form>
				</div>
			</div>

			<div class="container pt-4 pb-4 process-container" v-if="process">
				<h6 class="title mb-4">Please transfer to:</h6>

				<div class="bank" v-if="payment == 'Bank BRI'">
					<div class="image">
						<img src="@/assets/bri.png" alt="Bank BRI">
					</div>

					<div class="bank-detail">
						<h6 style="font-size: 12px">No. Rekening a/n RINYUAKNG SQUAD:</h6>
						<h5 class="title norek">0089-01-019493-53-9</h5>
					</div>
				</div>

				<div class="bank" v-if="payment == 'Bank Kalbar'">
					<div class="image">
						<img src="@/assets/bank-kalbar.jpg" alt="Bank Kalbar">
					</div>

					<div class="bank-detail">
						<h6 style="font-size: 12px">No. Rekening a/n Rinyuakng Squad:</h6>
						<h5 class="title norek">2021718656</h5>
					</div>
				</div>
				

				<form class="form-group mt-5" id="proofForm">
					<label for="paymentProof" class="btn btn-info w-100">Upload Payment Proof</label>
					<input type="file" name="picture" id="paymentProof" hidden @change="showFile">
				</form>

				<div class="floating-form" v-if="process">
					<div class="total-pay">Total Payment <span class="subtotal">Rp{{ Number(subtotal).toLocaleString() }}</span></div>
					
					<button type="button" @click="uploadPayment(data_id)" class="checkout button">Finish order</button>
				</div>
			</div>
		</template>
		
		<Nav></Nav>
	</div>
</template>

<script>
import Nav from '@/components/UserNav.vue'
import axios from 'axios'

export default {
	components: {
		Nav
	},
	data() {
		return {
			token: localStorage.getItem('token'),
			datas: '',
			process: false,
			payment: '',
			subtotal: 0,
			data_id: ''
		}
	},
	methods: {
		getData() {
			axios.get(`order?token=${this.token}`)
				.then(res => {
					this.datas = res.data;
				})
				.catch(err => {
					// this.$router.push('/login');
				});
		},
		uploadPayment(id) {
			const form = document.getElementById(`proofForm`);
			const formData = new FormData(form);
			formData.append('_method', 'PUT');

			axios.post(`order/${id}?token=${this.token}`, formData)
				.then(res => {
					this.getData();

					this.process = false;
					this.payment = '';
					this.subtotal = 0;
					this.data_id = '';
				})
				.catch(err => {
					alert(err.response.data.message);
				}); 
		},
		showFile() {
			const file = document.getElementById('paymentProof').files[0];
			const label = document.querySelector('#proofForm label');

			label.innerHTML = file.name;
		},
		done(id) {
            axios.put(`order/${id}/done?token=${this.token}`, {})
                .then(res => {
					this.getData();
                })
                .catch(err => {
                    console.log(err.response.data);
                }); 
        }
	},
	created() {
		if (this.token) this.getData();
	}
}
</script>

<style scoped>

a.proof {
	color: var(--primary);
	font-weight: bold;
	font-size: .8rem;
	cursor: pointer;
}

header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.order {
	position: relative;
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0.05);
}

.order .title {
	display: flex;
	align-items: center;
}

.status {
	position: absolute;
	right: 10px;
	top: 10px;
	margin: 0;
	padding: 5px 10px;
	border: 1px solid white;
	width: fit-content;
	font-size: 12px;
}


.upload-button {
	font-size: 12px;
	padding: 5px 20px
}

.norek {
	color: var(--primary2);
	font-weight: 600;
}

.bank {
	display: flex;
}

.bank .image {
	width: 25%;
	margin-right: 1rem;
}

.bank .image img {
	height: 100%;
	width: 100%;
	object-fit: contain;
}


.process-container {
	position: absolute;
	z-index: 1000;
	background-color: white;
	height: 100%;
	width: 100%;
	top: 50px;
	left: 0;
}

#proofForm .button {
	width: 100%;
}

.total-pay {
	margin-right: 10px;
	text-align: right;
	display: flex;
	flex-direction: column;
	font-size: 12px;
}

.total-pay span {
	text-align: right;
	align-self: end;
}

.floating-form {
	background-color: white;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	position: fixed;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
}

.sub {
	display: flex;
	flex-direction: column;
	margin-left: 1rem;
	font-size: 14px;
	line-height: 100%;
}

.total-pay {
	margin-left: 1rem;
	text-align: left;
}

.subtotal {
	margin-top: 5px;
	font-weight: bold;
	color: var(--primary2);
	font-size: 1.2rem;
	text-align: left;
	width: 100%;
}

.checkout {
	height: 100%;
	margin: 0;
	line-height: 60px;
	border-radius: 0;
	padding: 0 1rem;
	cursor: pointer;
	color: white;
}

</style>